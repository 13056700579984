import React from 'react';
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import { graphql } from 'gatsby';
import Img from "gatsby-image";
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import Pulse from 'react-reveal/Pulse';

const parallax = {
    backgroundImage: 'url(../rec8LbET5Sn3Ykm3J.jpg)',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const paraOne = {
    backgroundImage: 'url(../recYC3lyRKvot30MC.jpg)',
    height: '50vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const recdIu9ePVZZFbApi = {
    backgroundImage: 'url(../recdIu9ePVZZFbApi.jpg)',
    height: '50vh',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const recbJuYHDJlMSBR0S = {
    backgroundImage: 'url(../recbJuYHDJlMSBR0S.jpg)',
    height: '50vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const recXNvi8bgPHYSW5l = {
    backgroundImage: 'url(../recXNvi8bgPHYSW5l.jpg)',
    height: '50vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const recjxjaXdp6gCEWmQ = {
    backgroundImage: 'url(../recjxjaXdp6gCEWmQ.jpg)',
    height: '50vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const rec9dOlqgPhKGlzgR = {
    backgroundImage: 'url(../rec9dOlqgPhKGlzgR.jpg)',
    height: '50vh',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const rec3kYBIb1ARi2FWC = {
    backgroundImage: 'url(../rec3kYBIb1ARi2FWC.jpg)',
    height: '50vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const recVNXlBbOgxRlgLU = {
    backgroundImage: 'url(../recVNXlBbOgxRlgLU.jpg)',
    height: '50vh',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const rec3TAMIxyMsUbvA7 = {
    backgroundImage: 'url(../rec3TAMIxyMsUbvA7.jpg)',
    height: '50vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const recrWaTnr0yN9auvD = {
    backgroundImage: 'url(../recrWaTnr0yN9auvD.jpg)',
    height: '50vh',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const receuZ5ZoafFZuFJE = {
    backgroundImage: 'url(../receuZ5ZoafFZuFJE.jpg)',
    height: '50vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const rec9uNIkpg21OoG8H = {
    backgroundImage: 'url(../rec9uNIkpg21OoG8H.jpg)',
    height: '50vh',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const recR7o6IvKZQZyg74 = {
    backgroundImage: 'url(../recR7o6IvKZQZyg74.jpg)',
    height: '50vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const recU3ojLItndAhCfJ = {
    backgroundImage: 'url(../recU3ojLItndAhCfJ.jpg)',
    height: '50vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const recvlxD7QCj3ubAFr = {
    backgroundImage: 'url(../recvlxD7QCj3ubAFr.jpg)',
    height: '50vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const reccxjQS2VeaC3spf = {
    backgroundImage: 'url(../reccxjQS2VeaC3spf.jpg)',
    height: '50vh',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const recO6rWYRp78KEyUU = {
    backgroundImage: 'url(../recO6rWYRp78KEyUU.jpg)',
    height: '50vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const rec4ZmM4haoRrZH1S = {
    backgroundImage: 'url(../rec4ZmM4haoRrZH1S.jpg)',
    height: '50vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const recovI6gIMr4JzwOg = {
    backgroundImage: 'url(../recovI6gIMr4JzwOg.jpg)',
    height: '50vh',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const recfQYBoJZQ1FBV0a = {
    backgroundImage: 'url(../recfQYBoJZQ1FBV0a.jpg)',
    height: '50vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const recKQ0uxAd3iyVmwR = {
    backgroundImage: 'url(../recKQ0uxAd3iyVmwR.jpg)',
    height: '50vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const recBvQYpf2a5Mv758 = {
    backgroundImage: 'url(../recBvQYpf2a5Mv758.jpg)',
    height: '50vh',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const recKslhgq7km7eB3d = {
    backgroundImage: 'url(../recKslhgq7km7eB3d.jpg)',
    height: '50vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const reckqk9yhoHLENIiy = {
    backgroundImage: 'url(../reckqk9yhoHLENIiy.jpg)',
    height: '50vh',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const recpaCXZdjJc9JZe0 = {
    backgroundImage: 'url(../recpaCXZdjJc9JZe0.jpg)',
    height: '50vh',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const rec7yTl9hSzBM4utN = {
    backgroundImage: 'url(../rec7yTl9hSzBM4utN.jpg)',
    height: '50vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const recEIAFkiJqbsnFhY = {
    backgroundImage: 'url(../recEIAFkiJqbsnFhY.jpg)',
    height: '50vh',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const recckwkkM3Som23mb = {
    backgroundImage: 'url(../recckwkkM3Som23mb.jpg)',
    height: '50vh',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const reciDxPqg118J6JhW = {
    backgroundImage: 'url(../reciDxPqg118J6JhW.jpg)',
    height: '50vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const credit = {
    background: 'url(../rec2blEDjR4zOQqZY.jpg)',
    filter: 'grayscale(100%)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'bottom',
    alignItems: 'bottom',
}


export default ({ data }) => (
    <div>
        <Layout>
        <Helmet>
            <title>{data.meta.data.story} | Oberlin Photo Stories</title>
            
            <meta name="title" content={data.meta.data.story} />
            <meta name="description" content={data.meta.data.description} />
            
            <meta property="og:type" content="website" />
            <meta property="og:url" content={data.meta.data.metaurl} />
            <meta property="og:title" content={data.meta.data.story} />
            <meta property="og:description" content={data.meta.data.description} />
            <meta property="og:image" content="https://photo-stories.oberlin.edu/rec8LbET5Sn3Ykm3J.jpg" />
            <meta property="og:image:width" content="2000" />
            <meta property="og:image:height" content="1333" />
            <meta property="og:image:alt" content={data.meta.data.alt} />
            <meta property="fb:app_id" content="1571317633107125" />
            
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content={data.meta.data.metaurl} />
            <meta property="twitter:title" content={data.meta.data.story} />
            <meta property="twitter:description" content={data.meta.data.description} />
            <meta property="twitter:image" content="https://photo-stories.oberlin.edu/rec8LbET5Sn3Ykm3J.jpg" />
            <meta property="twitter:image:alt" content={data.meta.data.alt} />
        </Helmet>
        {/* hero */}
        <header>
        <div className="wrapper">
            <div style={parallax} className="hero parallax" role="img" aria-label={data.hero.data.alt}></div>
            <div className="title title-band default text-center">
                <h1 className="display-3 text-light">{data.title.data.title}</h1>
                <a href="#ss" aria-label="Skip to main content"><span className="fas fa-chevron-down fa-3x mt-5 text-white"></span></a>
            </div>
        </div>
        </header>

        <main>
            <div className="anchor" id="ss"></div>
            <div className="container my-4 story">
                {/* intro */}
                <section>
                <div className="col-lg-10 text-center mx-auto lead mt-5" dangerouslySetInnerHTML={{
                    __html: data.intro.data.text.childMarkdownRemark.html,
                    }} />
                </section>
            </div>

            <section>
            <div className="container story mt-5">
                {data.work.nodes.map(node => (
                    <Fade>
                    <div className="col-lg-10 col-12 mx-auto mb-4">
                    <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} key={node.recordId} className="img-fluid" />
                    {node.data.text && (
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: node.data.text.childMarkdownRemark.html,
                        }} />
                    )}
                    </div>
                    </Fade>
                ))}
            </div>
            <Fade>
            <div style={paraOne} role="img" aria-label={data.paraOne.data.alt} className="parallax mt-5"></div>
            <div className="mt-2 mx-5 story" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.paraOne.data.text.childMarkdownRemark.html,
                    }} />
            </Fade>
            <div className="container story mt-5">
                {data.movein.nodes.map(node => (
                    <Fade>
                    <div className="col-lg-10 col-12 mx-auto mb-4">
                    <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} key={node.recordId} className="img-fluid" />
                    {node.data.text && (
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: node.data.text.childMarkdownRemark.html,
                        }} />
                    )}
                    </div>
                    </Fade>
                ))}
            </div>
            <Fade>
            <div style={recdIu9ePVZZFbApi} role="img" aria-label={data.recdIu9ePVZZFbApi.data.alt} className="parallax mt-5"></div>
            <div className="mt-2 mx-5 story" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recdIu9ePVZZFbApi.data.text.childMarkdownRemark.html,
                    }} />
            </Fade>
            <div className="container story mt-5">
                {data.eat.nodes.map(node => (
                    <Fade>
                    <div className="col-lg-10 col-12 mx-auto mb-4">
                    <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} key={node.recordId} className="img-fluid" />
                    {node.data.text && (
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: node.data.text.childMarkdownRemark.html,
                        }} />
                    )}
                    </div>
                    </Fade>
                ))}
            </div>
            <Fade>
            <div style={recbJuYHDJlMSBR0S} role="img" aria-label={data.recbJuYHDJlMSBR0S.data.alt} className="parallax mt-5"></div>
            <div className="mt-2 mx-5 story" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recbJuYHDJlMSBR0S.data.text.childMarkdownRemark.html,
                    }} />
            </Fade>
            <div className="container story mt-5 row mx-auto">
                {data.winter.nodes.map(node => (
                    <div className="col-lg-6 mb-lg-0 mb-4" key={node.recordId}>
                        <Fade>
                        <Img fixed={node.data.file.localFiles[0].childImageSharp.fixed} alt={node.data.alt} className="w-100" />
                        </Fade>
                    </div>
                ))}
            </div>
            <Fade>
            <div style={recXNvi8bgPHYSW5l} role="img" aria-label={data.recXNvi8bgPHYSW5l.data.alt} className="parallax mt-5"></div>
            <div className="mt-2 mx-5 story" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recXNvi8bgPHYSW5l.data.text.childMarkdownRemark.html,
                    }} />
            </Fade>
            <div className="container story mt-5">
                {data.class.nodes.map(node => (
                    <Fade>
                    <div className="col-lg-10 col mx-auto mb-4">
                    <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} key={node.recordId} className="img-fluid" />
                    {node.data.text && (
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: node.data.text.childMarkdownRemark.html,
                        }} />
                    )}
                    </div>
                    </Fade>
                ))}
            </div>
            <Fade>
            <div style={recjxjaXdp6gCEWmQ} role="img" aria-label={data.recjxjaXdp6gCEWmQ.data.alt} className="parallax mt-5"></div>
            <div className="mt-2 mx-5 story" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recjxjaXdp6gCEWmQ.data.text.childMarkdownRemark.html,
                    }} />
            </Fade>
            <div className="container story mt-5">
            <div className="col-lg-10 col mx-auto mb-4">
                <Fade>
                <Img fluid={data.rec9gGfwFO5O6WoX3.data.file.localFiles[0].childImageSharp.fluid} alt={data.rec9gGfwFO5O6WoX3.data.alt} />
                <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.rec9gGfwFO5O6WoX3.data.text.childMarkdownRemark.html,
                }} />
                </Fade>
            </div>
            </div>
            <Fade>
            <div style={rec9dOlqgPhKGlzgR} role="img" aria-label={data.rec9dOlqgPhKGlzgR.data.alt} className="parallax mt-5"></div>
            <div className="mt-2 mx-5 story" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.rec9dOlqgPhKGlzgR.data.text.childMarkdownRemark.html,
                    }} />
            </Fade>
            <div className="container story mt-5">
                {data.spring.nodes.map(node => (
                    <Fade>
                    <div className="col-lg-10 col mx-auto mb-4">
                    <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} key={node.recordId} className="img-fluid" />
                    {node.data.text && (
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: node.data.text.childMarkdownRemark.html,
                        }} />
                    )}
                    </div>
                    </Fade>
                ))}
            </div>
            <Fade>
            <div style={rec3kYBIb1ARi2FWC} role="img" aria-label={data.rec3kYBIb1ARi2FWC.data.alt} className="parallax mt-5"></div>
            <div className="mt-2 mx-5 story" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.rec3kYBIb1ARi2FWC.data.text.childMarkdownRemark.html,
                    }} />
            </Fade>
            <div className="container story mt-5">
            <div className="col-lg-10 col mx-auto mb-4">
                <Fade>
                <Img fluid={data.recuiuFa7KloVQNsU.data.file.localFiles[0].childImageSharp.fluid} alt={data.recuiuFa7KloVQNsU.data.alt} className="mb-4" />
                </Fade>
                <Slide right>
                <Img fluid={data.recAq9cSYKnIBu3Rm.data.file.localFiles[0].childImageSharp.fluid} alt={data.recAq9cSYKnIBu3Rm.data.alt} />
                <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.recAq9cSYKnIBu3Rm.data.text.childMarkdownRemark.html,
                }} />
                </Slide>
                {data.spring2.nodes.map(node => (
                    <Fade>
                    <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} key={node.recordId} className="img-fluid" />
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: node.data.text.childMarkdownRemark.html,
                        }} />
                    </Fade>
                ))}
            </div>
            </div>
            <Fade>
            <div style={recVNXlBbOgxRlgLU} role="img" aria-label={data.recVNXlBbOgxRlgLU.data.alt} className="parallax mt-5"></div>
            <div className="mt-2 mx-5 story" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recVNXlBbOgxRlgLU.data.text.childMarkdownRemark.html,
                    }} />
            </Fade>
            <div className="container story mt-5">
                {data.april.nodes.map(node => (
                    <Fade>
                    <div className="col-lg-10 col mx-auto mb-4">
                    <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} key={node.recordId} className="img-fluid" />
                    {node.data.text && (
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: node.data.text.childMarkdownRemark.html,
                        }} />
                    )}
                    </div>
                    </Fade>
                ))}
                {data.may.nodes.map(node => (
                    <Fade>
                    <div className="col-lg-10 col mx-auto mb-4">
                    <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} key={node.recordId} className="img-fluid" />
                    {node.data.text && (
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: node.data.text.childMarkdownRemark.html,
                        }} />
                    )}
                    </div>
                    </Fade>
                ))}
            </div>
            <Fade>
            <div style={rec3TAMIxyMsUbvA7} role="img" aria-label={data.rec3TAMIxyMsUbvA7.data.alt} className="parallax mt-5"></div>
            <div className="mt-2 mx-5 story" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.rec3TAMIxyMsUbvA7.data.text.childMarkdownRemark.html,
                    }} />
            </Fade>
            <div className="container story mt-5 row mx-auto">
                {data.art.nodes.map(node => (
                    <Fade>
                    <div className="col-lg-4 col-12 mx-auto mb-4">
                    <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} key={node.recordId} className="img-fluid" />
                    </div>
                    </Fade>
                ))}
            </div>
            <div className="container story mt-5">
                {data.crw.nodes.map(node => (
                    <Fade>
                    <div className="col-lg-10 col mx-auto mb-4">
                    <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} key={node.recordId} className="img-fluid" />
                    {node.data.text && (
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: node.data.text.childMarkdownRemark.html,
                        }} />
                    )}
                    </div>
                    </Fade>
                ))}
            </div>
            <Fade>
            <div style={recrWaTnr0yN9auvD} role="img" aria-label={data.recrWaTnr0yN9auvD.data.alt} className="parallax mt-5"></div>
            <div className="mt-2 mx-5 story" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recrWaTnr0yN9auvD.data.text.childMarkdownRemark.html,
                    }} />
            </Fade>
            <div className="container story mt-5">
            <div className="col-lg-10 col mx-auto mb-4">
                <Fade>
                <Img fluid={data.recEzd22R3WYsD1rh.data.file.localFiles[0].childImageSharp.fluid} alt={data.recEzd22R3WYsD1rh.data.alt} />
                </Fade>
            </div>
            </div>
            <Fade>
            <div style={receuZ5ZoafFZuFJE} role="img" aria-label={data.receuZ5ZoafFZuFJE.data.alt} className="parallax mt-5"></div>
            <div className="mt-2 mx-5 story" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.receuZ5ZoafFZuFJE.data.text.childMarkdownRemark.html,
                    }} />
            </Fade>
            <div className="container story mt-5">
            <div className="col-lg-10 col mx-auto">
                <Fade className="mb-4">
                <Img fluid={data.rec1fLXvzApGwpkmU.data.file.localFiles[0].childImageSharp.fluid} alt={data.rec1fLXvzApGwpkmU.data.alt} />
                <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.rec1fLXvzApGwpkmU.data.text.childMarkdownRemark.html,
                    }} />
                </Fade>
                <Fade className="mb-4">
                <Img fluid={data.rechMUwwDzifqks4T.data.file.localFiles[0].childImageSharp.fluid} alt={data.rechMUwwDzifqks4T.data.alt} />
                <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.rechMUwwDzifqks4T.data.text.childMarkdownRemark.html,
                    }} />
                </Fade>
            </div>
            </div>
            <Fade>
            <div style={rec9uNIkpg21OoG8H} role="img" aria-label={data.rec9uNIkpg21OoG8H.data.alt} className="parallax mt-5"></div>
            <div className="mt-2 mx-5 story" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.rec9uNIkpg21OoG8H.data.text.childMarkdownRemark.html,
                    }} />
            </Fade>
            <div className="container story mt-5">
                {data.june1.nodes.map(node => (
                    <Fade>
                    <div className="col-lg-10 col mx-auto mb-4">
                    <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} key={node.recordId} className="img-fluid" />
                    {node.data.text && (
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: node.data.text.childMarkdownRemark.html,
                        }} />
                    )}
                    </div>
                    </Fade>
                ))}
            </div>
            <Fade>
            <div style={recR7o6IvKZQZyg74} role="img" aria-label={data.recR7o6IvKZQZyg74.data.alt} className="parallax mt-5"></div>
            <div className="mt-2 mx-5 story" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recR7o6IvKZQZyg74.data.text.childMarkdownRemark.html,
                    }} />
            </Fade>
            <div className="container story mt-5">
                {data.june2.nodes.map(node => (
                    <Fade>
                    <div className="col-lg-10 col mx-auto mb-4">
                    <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} key={node.recordId} className="img-fluid" />
                    {node.data.text && (
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: node.data.text.childMarkdownRemark.html,
                        }} />
                    )}
                    </div>
                    </Fade>
                ))}
                <Pulse>
                <div className="col-lg-10 col mx-auto mb-4">
                <Img fluid={data.recWFVKhTiqWmq0W5.data.file.localFiles[0].childImageSharp.fluid} alt={data.recWFVKhTiqWmq0W5.data.alt} />
                <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recWFVKhTiqWmq0W5.data.text.childMarkdownRemark.html,
                    }} />
                </div>
                </Pulse>
            </div>
            <Fade>
            <div style={recU3ojLItndAhCfJ} role="img" aria-label={data.recU3ojLItndAhCfJ.data.alt} className="parallax mt-5"></div>
            <div className="mt-2 mx-5 story" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recU3ojLItndAhCfJ.data.text.childMarkdownRemark.html,
                    }} />
            </Fade>
            <div className="container story mt-5">
                {data.july.nodes.map(node => (
                    <Fade>
                    <div className="col-lg-10 col mx-auto mb-4">
                    <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} key={node.recordId} className="img-fluid" />
                    {node.data.text && (
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: node.data.text.childMarkdownRemark.html,
                        }} />
                    )}
                    </div>
                    </Fade>
                ))}
            </div>
            <Fade>
            <div style={recvlxD7QCj3ubAFr} role="img" aria-label={data.recvlxD7QCj3ubAFr.data.alt} className="parallax mt-5"></div>
            <div className="mt-2 mx-5 story" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recvlxD7QCj3ubAFr.data.text.childMarkdownRemark.html,
                    }} />
            </Fade>
            <div className="container story mt-5">
                <Fade>
                <div className="col-lg-10 col mx-auto mb-4">
                <Img fluid={data.recszPgqdpdwgYUS6.data.file.localFiles[0].childImageSharp.fluid} alt={data.recszPgqdpdwgYUS6.data.alt} />
                </div>
                </Fade>
                <Pulse>
                <div className="col-lg-10 col mx-auto mb-4">
                <Img fluid={data.rec0rtZVBvf0IGAqD.data.file.localFiles[0].childImageSharp.fluid} alt={data.rec0rtZVBvf0IGAqD.data.alt} />
                <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.rec0rtZVBvf0IGAqD.data.text.childMarkdownRemark.html,
                    }} />
                </div>
                </Pulse>
                {data.august.nodes.map(node => (
                    <Fade>
                    <div className="col-lg-10 col mx-auto mb-4">
                    <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} key={node.recordId} className="img-fluid" />
                    {node.data.text && (
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: node.data.text.childMarkdownRemark.html,
                        }} />
                    )}
                    </div>
                    </Fade>
                ))}
            </div>
            <div className="container story mt-5">
            <div className="row">
                {data.dance.nodes.map(node => (
                    <div className="col-lg-6 mb-lg-0 mb-4" key={node.recordId}>
                        <Img fixed={node.data.file.localFiles[0].childImageSharp.fixed} alt={node.data.alt} className="w-100" />
                        {node.data.text && (
                        <div style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: node.data.text.childMarkdownRemark.html,
                        }} className="mt-2" />
                        )}
                    </div>
                ))}
            </div>
            </div>
            <Fade>
            <div style={reccxjQS2VeaC3spf} role="img" aria-label={data.reccxjQS2VeaC3spf.data.alt} className="parallax mt-5"></div>
            <div className="mt-2 mx-5 story" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.reccxjQS2VeaC3spf.data.text.childMarkdownRemark.html,
                    }} />
            </Fade>
            <div className="container story mt-5">
                <Fade>
                <div className="col-lg-10 col mx-auto mb-4">
                <Img fluid={data.rec8JKiiQEUDWPvNh.data.file.localFiles[0].childImageSharp.fluid} alt={data.rec8JKiiQEUDWPvNh.data.alt} />
                <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.rec8JKiiQEUDWPvNh.data.text.childMarkdownRemark.html,
                    }} />
                </div>
                </Fade>
            </div>
            <Fade>
            <div style={recO6rWYRp78KEyUU} role="img" aria-label={data.recO6rWYRp78KEyUU.data.alt} className="parallax mt-5"></div>
            <div className="mt-2 mx-5 story" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recO6rWYRp78KEyUU.data.text.childMarkdownRemark.html,
                    }} />
            </Fade>
            <div className="container story mt-5">
                {data.orientation.nodes.map(node => (
                    <Fade>
                    <div className="col-lg-10 col mx-auto mb-4">
                    <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} key={node.recordId} className="img-fluid" />
                    {node.data.text && (
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: node.data.text.childMarkdownRemark.html,
                        }} />
                    )}
                    </div>
                    </Fade>
                ))}
            </div>
            <Fade>
            <div style={rec4ZmM4haoRrZH1S} role="img" aria-label={data.rec4ZmM4haoRrZH1S.data.alt} className="parallax mt-5"></div>
            <div className="mt-2 mx-5 story" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.rec4ZmM4haoRrZH1S.data.text.childMarkdownRemark.html,
                    }} />
            </Fade>
            <div className="container story mt-5">
                {data.oct1.nodes.map(node => (
                    <Fade>
                    <div className="col-lg-10 col mx-auto mb-4">
                    <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} key={node.recordId} className="img-fluid" />
                    {node.data.text && (
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: node.data.text.childMarkdownRemark.html,
                        }} />
                    )}
                    </div>
                    </Fade>
                ))}
            </div>
            <Fade>
            <div style={recovI6gIMr4JzwOg} role="img" aria-label={data.recovI6gIMr4JzwOg.data.alt} className="parallax mt-5"></div>
            <div className="mt-2 mx-5 story" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recovI6gIMr4JzwOg.data.text.childMarkdownRemark.html,
                    }} />
            </Fade>
            <div className="container story mt-5">
                {data.oct2.nodes.map(node => (
                    <Fade>
                    <div className="col-lg-10 col mx-auto mb-4">
                    <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} key={node.recordId} className="img-fluid" />
                    {node.data.text && (
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: node.data.text.childMarkdownRemark.html,
                        }} />
                    )}
                    </div>
                    </Fade>
                ))}
            </div>
            <Fade>
            <div style={recfQYBoJZQ1FBV0a} role="img" aria-label={data.recfQYBoJZQ1FBV0a.data.alt} className="parallax mt-5"></div>
            </Fade>
            <div className="container story mt-5">
                {data.oct3.nodes.map(node => (
                    <Fade>
                    <div className="col-lg-10 col mx-auto mb-4">
                    <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} key={node.recordId} className="img-fluid" />
                    {node.data.text && (
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: node.data.text.childMarkdownRemark.html,
                        }} />
                    )}
                    </div>
                    </Fade>
                ))}
            </div>
            <Fade>
            <div style={recKQ0uxAd3iyVmwR} role="img" aria-label={data.recKQ0uxAd3iyVmwR.data.alt} className="parallax mt-5"></div>
            <div className="mt-2 mx-5 story" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recKQ0uxAd3iyVmwR.data.text.childMarkdownRemark.html,
                    }} />
            </Fade>
            <div className="container story mt-5">
                {data.oct4.nodes.map(node => (
                    <Fade>
                    <div className="col-lg-10 col mx-auto mb-4">
                    <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} key={node.recordId} className="img-fluid" />
                    {node.data.text && (
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: node.data.text.childMarkdownRemark.html,
                        }} />
                    )}
                    </div>
                    </Fade>
                ))}
                <Pulse>
                <div className="col-lg-10 col mx-auto mb-4">
                <Img fluid={data.recr0IS0i95LCm6ST.data.file.localFiles[0].childImageSharp.fluid} alt={data.recr0IS0i95LCm6ST.data.alt} />
                <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recr0IS0i95LCm6ST.data.text.childMarkdownRemark.html,
                    }} />
                </div>
                </Pulse>
            </div>
            <Fade>
            <div style={recBvQYpf2a5Mv758} role="img" aria-label={data.recBvQYpf2a5Mv758.data.alt} className="parallax mt-5"></div>
            <div className="mt-2 mx-5 story" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recBvQYpf2a5Mv758.data.text.childMarkdownRemark.html,
                    }} />
            </Fade>
            <div className="container story mt-5">
                {data.oct5.nodes.map(node => (
                    <Fade>
                    <div className="col-lg-10 col mx-auto mb-4">
                    <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} key={node.recordId} className="img-fluid" />
                    {node.data.text && (
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: node.data.text.childMarkdownRemark.html,
                        }} />
                    )}
                    </div>
                    </Fade>
                ))}
            </div>
            <Fade>
            <div style={recKslhgq7km7eB3d} role="img" aria-label={data.recKslhgq7km7eB3d.data.alt} className="parallax mt-5"></div>
            <div className="mt-2 mx-5 story" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recKslhgq7km7eB3d.data.text.childMarkdownRemark.html,
                    }} />
            </Fade>
            <div className="container story mt-5">
                {data.campus.nodes.map(node => (
                    <Fade>
                    <div className="col-lg-10 col mx-auto mb-4">
                    <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} key={node.recordId} className="img-fluid" />
                    {node.data.text && (
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: node.data.text.childMarkdownRemark.html,
                        }} />
                    )}
                    </div>
                    </Fade>
                ))}
            </div>
            <Fade>
            <div style={reckqk9yhoHLENIiy} role="img" aria-label={data.reckqk9yhoHLENIiy.data.alt} className="parallax mt-5"></div>
            </Fade>
            <div className="container story mt-5">
                {data.nov.nodes.map(node => (
                    <Fade>
                    <div className="col-lg-10 col mx-auto mb-4">
                    <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} key={node.recordId} className="img-fluid" />
                    {node.data.text && (
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: node.data.text.childMarkdownRemark.html,
                        }} />
                    )}
                    </div>
                    </Fade>
                ))}
            </div>
            <Fade>
            <div style={recpaCXZdjJc9JZe0} role="img" aria-label={data.recpaCXZdjJc9JZe0.data.alt} className="parallax mt-5"></div>
            <div className="mt-2 mx-5 story" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recpaCXZdjJc9JZe0.data.text.childMarkdownRemark.html,
                    }} />
            </Fade>
            <div className="container story mt-5">
                {data.nov2.nodes.map(node => (
                    <Fade>
                    <div className="col-lg-10 col mx-auto mb-4">
                    <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} key={node.recordId} className="img-fluid" />
                    {node.data.text && (
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: node.data.text.childMarkdownRemark.html,
                        }} />
                    )}
                    </div>
                    </Fade>
                ))}
            </div>
            <Fade>
            <div style={rec7yTl9hSzBM4utN} role="img" aria-label={data.rec7yTl9hSzBM4utN.data.alt} className="parallax mt-5"></div>
            <div className="mt-2 mx-5 story" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.rec7yTl9hSzBM4utN.data.text.childMarkdownRemark.html,
                    }} />
            </Fade>
            <div className="container story mt-5">
                {data.nov3.nodes.map(node => (
                    <Fade>
                    <div className="col-lg-10 col mx-auto mb-4">
                    <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} key={node.recordId} className="img-fluid" />
                    {node.data.text && (
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: node.data.text.childMarkdownRemark.html,
                        }} />
                    )}
                    </div>
                    </Fade>
                ))}
            </div>
            <Fade>
            <div style={recEIAFkiJqbsnFhY} role="img" aria-label={data.recEIAFkiJqbsnFhY.data.alt} className="parallax mt-5"></div>
            <div className="mt-2 mx-5 story" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recEIAFkiJqbsnFhY.data.text.childMarkdownRemark.html,
                    }} />
            </Fade>
            <div className="container story mt-5">
                {data.dec.nodes.map(node => (
                    <Fade>
                    <div className="col-lg-10 col mx-auto mb-4">
                    <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} key={node.recordId} className="img-fluid" />
                    {node.data.text && (
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: node.data.text.childMarkdownRemark.html,
                        }} />
                    )}
                    </div>
                    </Fade>
                ))}
            </div>
            <Fade>
            <div style={recckwkkM3Som23mb} role="img" aria-label={data.recckwkkM3Som23mb.data.alt} className="parallax mt-5"></div>
            <div className="mt-2 mx-5 story" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recckwkkM3Som23mb.data.text.childMarkdownRemark.html,
                    }} />
            </Fade>
            <div className="container story mt-5">
                {data.dec2.nodes.map(node => (
                    <Fade>
                    <div className="col-lg-10 col mx-auto mb-4">
                    <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} key={node.recordId} className="img-fluid" />
                    {node.data.text && (
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: node.data.text.childMarkdownRemark.html,
                        }} />
                    )}
                    </div>
                    </Fade>
                ))}
            </div>
            <Fade>
            <div style={reciDxPqg118J6JhW} role="img" aria-label={data.reciDxPqg118J6JhW.data.alt} className="parallax mt-5"></div>
            <div className="mt-2 mx-5 story" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.reciDxPqg118J6JhW.data.text.childMarkdownRemark.html,
                    }} />
            </Fade>
            <div className="container story mt-5">
                {data.dec3.nodes.map(node => (
                    <Fade>
                    <div className="col-lg-10 col mx-auto mb-4">
                    <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} key={node.recordId} className="img-fluid" />
                    {node.data.text && (
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: node.data.text.childMarkdownRemark.html,
                        }} />
                    )}
                    </div>
                    </Fade>
                ))}
            </div>
            </section>

            {/* Credit */}
            <section style={{marginTop:`80px`}}>
                <div style={credit} role="img" aria-label={data.credit.data.alt} className="py-5 mb-0 text-center d-flex justify-content-center">
                    <div className="contrast text-white">
                        <p className="h3 credit-title text-white">{data.credit.data.title}</p>
                        <div dangerouslySetInnerHTML={{
                        __html: data.credit.data.text.childMarkdownRemark.html,
                        }} />
                    </div>
                </div>
            </section>
        </main>
        </Layout>
    </div>
)

export const query = graphql`
{
    meta: airtable(recordId: {eq: "rec8LbET5Sn3Ykm3J"}) {
        data {
          file {
            localFiles {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          alt
          story
          metaurl
          description
        }
      }

    hero: airtable(data: {identifier: {eq: "rec8LbET5Sn3Ykm3J"}}) {
        data {
            alt
        }
    }

    title: airtable(data: {identifier: {eq: "recz3N7YHycCmZgzO"}}) {
        data {
            title
        }
    }

    intro: airtable(data: {identifier: {eq: "reccmvzBIXOggqUYh"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    work: allAirtable(filter: {data: {story: {eq: "Year in Pictures"}, group: {eq: "work"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                text {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }

    paraOne: airtable(recordId: {eq: "recYC3lyRKvot30MC"}) {
        data {
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    movein: allAirtable(filter: {data: {story: {eq: "Year in Pictures"}, group: {eq: "move-in"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                text {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }

    recdIu9ePVZZFbApi: airtable(recordId: {eq: "recdIu9ePVZZFbApi"}) {
        data {
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    eat: allAirtable(filter: {data: {story: {eq: "Year in Pictures"}, group: {eq: "eat"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                text {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }

    recbJuYHDJlMSBR0S: airtable(recordId: {eq: "recbJuYHDJlMSBR0S"}) {
        data {
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    winter: allAirtable(filter: {data: {story: {eq: "Year in Pictures"}, group: {eq: "winter"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fixed(cropFocus: ATTENTION, height: 350, quality: 85) {
                                ...GatsbyImageSharpFixed
                            }
                        }
                    }
                }
                alt
            }
        }
    }

    recXNvi8bgPHYSW5l: airtable(recordId: {eq: "recXNvi8bgPHYSW5l"}) {
        data {
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    class: allAirtable(filter: {data: {story: {eq: "Year in Pictures"}, group: {eq: "class"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                text {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }

    recjxjaXdp6gCEWmQ: airtable(recordId: {eq: "recjxjaXdp6gCEWmQ"}) {
        data {
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec9gGfwFO5O6WoX3: airtable(data: {identifier: {eq: "rec9gGfwFO5O6WoX3"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec9dOlqgPhKGlzgR: airtable(recordId: {eq: "rec9dOlqgPhKGlzgR"}) {
        data {
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    spring: allAirtable(filter: {data: {story: {eq: "Year in Pictures"}, group: {eq: "spring"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                text {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }

    rec3kYBIb1ARi2FWC: airtable(recordId: {eq: "rec3kYBIb1ARi2FWC"}) {
        data {
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recuiuFa7KloVQNsU: airtable(data: {identifier: {eq: "recuiuFa7KloVQNsU"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recAq9cSYKnIBu3Rm: airtable(data: {identifier: {eq: "recAq9cSYKnIBu3Rm"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    spring2: allAirtable(filter: {data: {story: {eq: "Year in Pictures"}, group: {eq: "spring2"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                text {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }

    recVNXlBbOgxRlgLU: airtable(recordId: {eq: "recVNXlBbOgxRlgLU"}) {
        data {
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    april: allAirtable(filter: {data: {story: {eq: "Year in Pictures"}, group: {eq: "april"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                text {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }

    may: allAirtable(filter: {data: {story: {eq: "Year in Pictures"}, group: {eq: "may"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                text {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }

    rec3TAMIxyMsUbvA7: airtable(recordId: {eq: "rec3TAMIxyMsUbvA7"}) {
        data {
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    art: allAirtable(filter: {data: {story: {eq: "Year in Pictures"}, group: {eq: "art"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
            }
        }
    }

    crw: allAirtable(filter: {data: {story: {eq: "Year in Pictures"}, group: {eq: "crw"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                text {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }

    recrWaTnr0yN9auvD: airtable(recordId: {eq: "recrWaTnr0yN9auvD"}) {
        data {
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recEzd22R3WYsD1rh: airtable(data: {identifier: {eq: "recEzd22R3WYsD1rh"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    receuZ5ZoafFZuFJE: airtable(recordId: {eq: "receuZ5ZoafFZuFJE"}) {
        data {
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec1fLXvzApGwpkmU: airtable(data: {identifier: {eq: "rec1fLXvzApGwpkmU"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rechMUwwDzifqks4T: airtable(data: {identifier: {eq: "rechMUwwDzifqks4T"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec9uNIkpg21OoG8H: airtable(recordId: {eq: "rec9uNIkpg21OoG8H"}) {
        data {
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    june1: allAirtable(filter: {data: {story: {eq: "Year in Pictures"}, group: {eq: "june1"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                text {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }

    recR7o6IvKZQZyg74: airtable(recordId: {eq: "recR7o6IvKZQZyg74"}) {
        data {
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    june2: allAirtable(filter: {data: {story: {eq: "Year in Pictures"}, group: {eq: "june2"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                text {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }

    recWFVKhTiqWmq0W5: airtable(data: {identifier: {eq: "recWFVKhTiqWmq0W5"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recU3ojLItndAhCfJ: airtable(recordId: {eq: "recU3ojLItndAhCfJ"}) {
        data {
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    july: allAirtable(filter: {data: {story: {eq: "Year in Pictures"}, group: {eq: "july"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                text {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }

    recvlxD7QCj3ubAFr: airtable(recordId: {eq: "recvlxD7QCj3ubAFr"}) {
        data {
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recszPgqdpdwgYUS6: airtable(data: {identifier: {eq: "recszPgqdpdwgYUS6"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    rec0rtZVBvf0IGAqD: airtable(data: {identifier: {eq: "rec0rtZVBvf0IGAqD"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    august: allAirtable(filter: {data: {story: {eq: "Year in Pictures"}, group: {eq: "august"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                text {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }

    dance: allAirtable(filter: {data: {story: {eq: "Year in Pictures"}, group: {eq: "dance"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fixed(cropFocus: ATTENTION, height: 350, quality: 85) {
                                ...GatsbyImageSharpFixed
                            }
                        }
                    }
                }
                alt
                text {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }

    reccxjQS2VeaC3spf: airtable(recordId: {eq: "reccxjQS2VeaC3spf"}) {
        data {
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec8JKiiQEUDWPvNh: airtable(data: {identifier: {eq: "rec8JKiiQEUDWPvNh"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recO6rWYRp78KEyUU: airtable(recordId: {eq: "recO6rWYRp78KEyUU"}) {
        data {
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    orientation: allAirtable(filter: {data: {story: {eq: "Year in Pictures"}, group: {eq: "orientation"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                text {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }

    rec4ZmM4haoRrZH1S: airtable(recordId: {eq: "rec4ZmM4haoRrZH1S"}) {
        data {
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    oct1: allAirtable(filter: {data: {story: {eq: "Year in Pictures"}, group: {eq: "oct1"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                text {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }

    recovI6gIMr4JzwOg: airtable(recordId: {eq: "recovI6gIMr4JzwOg"}) {
        data {
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    oct2: allAirtable(filter: {data: {story: {eq: "Year in Pictures"}, group: {eq: "oct2"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                text {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }

    recfQYBoJZQ1FBV0a: airtable(recordId: {eq: "recfQYBoJZQ1FBV0a"}) {
        data {
            alt
        }
    }

    oct3: allAirtable(filter: {data: {story: {eq: "Year in Pictures"}, group: {eq: "oct3"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                text {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }

    recKQ0uxAd3iyVmwR: airtable(recordId: {eq: "recKQ0uxAd3iyVmwR"}) {
        data {
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    oct4: allAirtable(filter: {data: {story: {eq: "Year in Pictures"}, group: {eq: "oct4"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                text {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }

    recr0IS0i95LCm6ST: airtable(data: {identifier: {eq: "recr0IS0i95LCm6ST"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recBvQYpf2a5Mv758: airtable(recordId: {eq: "recBvQYpf2a5Mv758"}) {
        data {
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    oct5: allAirtable(filter: {data: {story: {eq: "Year in Pictures"}, group: {eq: "oct5"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                text {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }

    recKslhgq7km7eB3d: airtable(recordId: {eq: "recKslhgq7km7eB3d"}) {
        data {
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    campus: allAirtable(filter: {data: {story: {eq: "Year in Pictures"}, group: {eq: "campus"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                text {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }

    reckqk9yhoHLENIiy: airtable(recordId: {eq: "reckqk9yhoHLENIiy"}) {
        data {
            alt
        }
    }

    nov: allAirtable(filter: {data: {story: {eq: "Year in Pictures"}, group: {eq: "nov"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                text {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }

    recpaCXZdjJc9JZe0: airtable(recordId: {eq: "recpaCXZdjJc9JZe0"}) {
        data {
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    nov2: allAirtable(filter: {data: {story: {eq: "Year in Pictures"}, group: {eq: "nov2"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                text {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }

    rec7yTl9hSzBM4utN: airtable(recordId: {eq: "rec7yTl9hSzBM4utN"}) {
        data {
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    nov3: allAirtable(filter: {data: {story: {eq: "Year in Pictures"}, group: {eq: "nov3"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                text {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }

    recEIAFkiJqbsnFhY: airtable(recordId: {eq: "recEIAFkiJqbsnFhY"}) {
        data {
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    dec: allAirtable(filter: {data: {story: {eq: "Year in Pictures"}, group: {eq: "dec"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                text {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }

    recckwkkM3Som23mb: airtable(recordId: {eq: "recckwkkM3Som23mb"}) {
        data {
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    dec2: allAirtable(filter: {data: {story: {eq: "Year in Pictures"}, group: {eq: "dec2"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                text {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }

    reciDxPqg118J6JhW: airtable(recordId: {eq: "reciDxPqg118J6JhW"}) {
        data {
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    dec3: allAirtable(filter: {data: {story: {eq: "Year in Pictures"}, group: {eq: "dec3"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                text {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }

    credit: airtable(recordId: {eq: "rec2blEDjR4zOQqZY"}) {
        data {
            alt
            title
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }
}  
`;